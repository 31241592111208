<template>
  <div class="title">
  client profile
  </div>
</template>

<script>

export default {
  name:'clientProfile',
  components:{
  }
};
</script>

<style lang="scss" scoped>
.title {
  height: 100vh;
  width:100%;
  justify-content: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  background:#422E87;
}
</style>